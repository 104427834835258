import request from "@/utils/request.js";

/*
直播视频
*/
// export const submit_video = (params) => request.post("/api/video/submit_video", params);
export const submit_video = (params) => request.post("/api/lackvideo/submit_video", params);
export const get_video_list = (params) => request.post("/api/video/get_video_list", params);
export const del_video = (params) => request.post("/api/video/del_video", params);

/*
互动话术
*/
export const get_one = (params) => request.post("/api/Interaction/get_one", params);
export const get_list = (params) => request.post("/api/Interaction/get_list", params);
export const add = (params) => request.post("/api/Interaction/add", params);
export const edit = (params) => request.post("/api/Interaction/edit", params);
export const del = (params) => request.post("/api/Interaction/del", params);

/*
商品讲解
*/
export const goods_add = (params) => request.post("/api/goods/add", params);
export const goods_get_list = (params) => request.post("/api/goods/get_list", params);
export const goods_edit_name = (params) => request.post("/api/goods/edit_name", params);
export const goods_del = (params) => request.post("/api/goods/del", params);
export const goods_query_data = (params) => request.post("/api/goods/query_data", params);
export const goods_add_data = (params) => request.post("/api/goods/add_data", params);
export const goods_add_query = (params) => request.post("/api/goods/add_query", params);
export const goods_del_data = (params) => request.post("/api/goods/del_data", params);
export const edit_text_card = (params) => request.post("/api/goods/edit_text_card", params);

/*
直播方案
*/
export const getlive = (params) => request.post("/api/live/get_live_list", params);
export const createlive = (params) => request.post("/api/live/create_live", params);
export const editlive = (params) => request.post("/api/live/edit_live", params);
export const dellive = (params) => request.post("/api/live/del_live", params);
export const getliveoption = (params) => request.post("/api/live/get_live", params);
export const add_live_goods = (params) => request.post("/api/live/add_live_goods", params);
export const get_live_goods = (params) => request.post("/api/live/get_live_goods", params);
export const get_live_goods_list = (params) => request.post("/api/live/get_live_goods_list", params);
export const del_live_video = (params) => request.post("/api/live/del_live_video", params);

/*
直播配置
*/
export const get_all = (params) => request.post("/api/liveconfig/get_all", params);
export const edit_all = (params) => request.post("/api/liveconfig/edit_all", params);
export const get_welcome = (params) => request.post("/api/liveconfig/get_welcome", params);
export const edit_welcome = (params) => request.post("/api/liveconfig/edit_welcome", params);
export const get_likes = (params) => request.post("/api/liveconfig/get_likes", params);
export const edit_likes = (params) => request.post("/api/liveconfig/edit_likes", params);
export const get_gift = (params) => request.post("/api/liveconfig/get_gift", params);
export const edit_gift = (params) => request.post("/api/liveconfig/edit_gift", params);
export const get_lead = (params) => request.post("/api/liveconfig/get_lead", params);
export const edit_lead = (params) => request.post("/api/liveconfig/edit_lead", params);
